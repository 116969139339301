@import './breakpoints.scss';
@import './typography.scss';

// primary colors

$fermata_red: #e80c26;
$fermata_violet: #6e56ff;
$red_orange_50_gradient: linear-gradient(90deg, #e80c26 0%, #ff4c00 100%);
$red_orange_75_gradient: linear-gradient(90deg, #e80c26 51.56%, #ff4c00 100%);
$violet_gradient: linear-gradient(90deg, #583fed, #6a19c7, #890bbb);;
// tints and shades

$red_02: #b60016;
$violet_02: #583fed;
$dark_grey_30: rgba(25, 27, 37, 0.3);
$hover_grey: #eeeaee;
$base-light-grey: #fafafa;
$light_grey_70: rgba(249, 247, 249, 0.7);
$white_30: rgba(255, 255, 255, 0.3);
$lower_footer: rgba(255, 255, 255, 0.1);
$static_Two_column_gradient: linear-gradient(
  90deg,
  #e80c26 0%,
  #e80c26 0.01%,
  #ff4c00 100%
);
$home_hero_gradient: linear-gradient(
  270deg,
  #000000 0%,
  rgba(0, 0, 0, 0) 98.09%
);
$box_shadow: #0e0b350a;

// neutral tones

$dark_grey: #191b25;
$medium_grey: #756f85;
$light_grey: #f9f7f9;
$grey_bg: #e6e5e5;
$white: #ffffff;

// charger status colors

$available: #009e5a;
$plugged-in: #ffc700;
$charging: #009df5;
$discharging: #644bfb;
$needs_attention: #e6008d;

// violet tones

$violet_900: #2c1199;
$violet_800: #3e24b8;
$violet_700: #4b31cf;
$violet_600: #593fe8;
$violet_500: #644bfb; // same as discharging
$violet_400: #7f6afc;
$violet_300: #9888fd;
$violet_200: #b2a5fe;
$violet_100: #ccc4ff;
$violet_50: #f0edff;

// buttons

.btn {
  font-family: 'TWKLausanne-450';
  font-size: 1.25vw;
  line-height: 1.25vw;
  font-weight: 450;
  text-align: center;
  padding: 1.111vw 2.222vw;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  @media (max-width: $breakpoint-md) {
    font-size: 14px;
    line-height: 14px;
    padding: 16px 24px;
  }
  &.primary_red {
    background: $red_orange_75_gradient;
    color: $white;
    border: none;
    &:hover {
      background: $fermata_red;
      box-shadow: 0px 4px 24px -16px rgba(167, 0, 20, 0.25);
    }
    &:active {
      background: $red_02;
    }
  }
  &.primary_white {
    background: $white;
    color: $fermata_red;
    border: none;
    &:hover {
      background: $hover_grey;
    }
    &:active {
      color: $red_02;
    }
  }
  &.secondary_red {
    color: $fermata_red;
    border: 2px solid $fermata_red;
    &:hover {
      background: $fermata_red;
      color: $white;
    }
    &:active {
      background: $red_02;
      color: $white;
      border: 2px solid $red_02;
    }
  }
  &.secondary_white {
    background: transparent;
    color: $white;
    border: 2px solid $white;
    &:hover {
      background: $white;
      color: $fermata_red;
    }
    &:active {
      color: $red_02;
      background: $white;
    }
  }
  &.tertiary_black {
    background: transparent;
    color: $dark_grey;
    border: none;
    &:hover {
      color: $fermata_red;
    }
    &:active {
      color: $red_02;
    }
    &.disabled {
      color: $dark_grey_30;
      background: transparent;
      cursor: auto;
      &:hover {
        color: $dark_grey_30;
        background: transparent;
      }
    }
    @media (max-width: $breakpoint-md) {
      padding: 16px 12px;
    }
  }
  &.tertiary_white {
    background: transparent;
    color: $white;
    border: none;
    &:hover {
      color: $hover_grey;
    }
    &:active {
      color: $light_grey_70;
    }
    &.disabled {
      color: $dark_grey_30;
      background: transparent;
      cursor: auto;
      &:hover {
        color: $dark_grey_30;
        background: transparent;
      }
    }
    @media (max-width: $breakpoint-md) {
      padding: 16px 12px;
    }
  }
  &.disabled {
    background: $dark_grey_30;
    cursor: auto;
    color: $white;
    border: none;
    &:hover {
      background: $dark_grey_30;
      color: $white;
      border: none;
    }
    &:active {
      color: $white;
      border: none;
    }
  }
}
