@font-face {
  font-family: 'TWKLausanne-300';
  src: url('/fonts/TWKLausanne-300.woff2') format('woff2'),
    url('/fonts/TWKLausanne-300.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TWKLausanne-300';
  src: url('/fonts/TWKLausanne-300.woff2') format('woff2'),
    url('/fonts/TWKLausanne-300.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TWKLausanne-300Italic';
  src: url('/fonts/TWKLausanne-300Italic.woff2') format('woff2'),
    url('/fonts/TWKLausanne-300Italic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TWKLausanne-450';
  src: url('/fonts/TWKLausanne-450.woff2') format('woff2'),
    url('/fonts/TWKLausanne-450.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TWKLausanne-450Italic';
  src: url('/fonts/TWKLausanne-450Italic.woff2') format('woff2'),
    url('/fonts/TWKLausanne-450Italic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TWKLausanne-450Italic';
  src: url('/fonts/TWKLausanne-450Italic.woff2') format('woff2'),
    url('/fonts/TWKLausanne-450Italic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TWKLausanne-450';
  src: url('/fonts/TWKLausanne-450.woff2') format('woff2'),
    url('/fonts/TWKLausanne-450.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TWKLausanne-300Italic';
  src: url('/fonts/TWKLausanne-300Italic.woff2') format('woff2'),
    url('/fonts/TWKLausanne-300Italic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TWKLausanne-700';
  src: url('/fonts/TWKLausanne-700.woff2') format('woff2'),
    url('/fonts/TWKLausanne-700.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TWKLausanne-700Italic';
  src: url('/fonts/TWKLausanne-700Italic.woff2') format('woff2'),
    url('/fonts/TWKLausanne-700Italic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TWKLausanne-700Italic';
  src: url('/fonts/TWKLausanne-700Italic.woff2') format('woff2'),
    url('/fonts/TWKLausanne-700Italic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TWKLausanne-700';
  src: url('/fonts/TWKLausanne-700.woff2') format('woff2'),
    url('/fonts/TWKLausanne-700.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
